import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyContainer = lazy(
  () => import("./container-Cpbqk51N.js").then((module) => ({
    default: module.Container
  }))
);
function Container({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyContainer, { ...props });
}
const useContainerContent = () => {
  return useMemo(
    () => ({
      container: {
        component: Container
      }
    }),
    []
  );
};
export {
  LazyContainer as Container,
  useContainerContent
};
